import './App.css';
import SearchApi from './component/SearchApi';

function App() {
  return (
    <div className="App">
     <SearchApi></SearchApi>
    </div>
  );
}

export default App;

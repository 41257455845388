import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid"; // Grid version 1
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";
import "../style.css";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

const SearchApi = () => {
  const [tokenApi, setTokenApi] = useState(null);
  const [bookingFile, setbookingFile] = useState({});
  const [countries, setCountries] = useState({});
  const [renderAgain, setRenderAgain] = useState("");

  const getCountries = async (token) => {
    const getCountriesUrl =
      "https://tbapi-live.travelbooster.com/tbapi/v1/Lookups/GetCountries";
    await axios
      .request({
        method: "GET",
        url: getCountriesUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then(function (response) {
        // handle success
        setCountries(response.data);
      })
      .catch(function (error) {
        setCountries([]);
        alert("Travelbooster API is not returning the country list");
        // handle error
        console.log(error);
      })
      .finally(function () { });
  };
  const [refreshPnr, setRefreshPnr] = useState(0);
  useEffect(() => {
    /* global spHelper */
    const token = spHelper.getToken();
    setTokenApi(JSON.parse(token));
    document.title = `Travelbooster Plugin with ${spHelper.getVersion()} and UI v1.6`;
    try {
      const bf = spHelper.getBookingFile();
      setbookingFile(bf);
    } catch (e) {
      alert("please restart smart point or sign on again: " + e.toString());
      setSearchResult([]);
      setTokenApi({});
    }
  }, [refreshPnr]);


  useEffect(() => {
    if (tokenApi) getCountries(tokenApi.access_token);
  }, [tokenApi]);


  const [contact, setContact] = useState({
    index: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    personalid: "",
  });

  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedResult, setSelectedResult] = useState([]);
  const handleSearch = async () => {
    setIsSearching(true);
    const searchUrl =
      "https://tbapi-live.travelbooster.com/tbapi/v1/Customer/FindCustomers";
    await axios
      .request({
        method: "POST",
        url: searchUrl,
        headers: {
          Authorization: `Bearer ${tokenApi.access_token}`,
          Accept: "application/json",
        },
        data: {
          Index: contact.index,
          FirstName: contact.firstname,
          LastName: contact.lastname,
          Phone: contact.phone,
          Email: contact.email,
          PersonalId: contact.personalid,
        },
      })
      .then(function (response) {
        // handle success
        if (response.data && response.data.length > 0) {
          response.data.forEach((d) => {
            if (d.FrequentFlyerData != null && d.FrequentFlyerData.length > 0) {
              d.IsFrequentFlyerNumberSelected = true;
            }
            if (d.Passports != null && d.Passports.length > 0) {
              d.IsPassportSelected = true;
            }
            if (d.PhonesList.length > 0) {
              d.IsPhoneSelected = true;
            }
            if (d.EMail != null) {
              d.IsEmailSelected = true;
            }
          }
          );
        }
        setSearchResult(response.data);
      })
      .catch(function (error) {
        setSearchResult([]);
        if (error.response.status === 429) {
          alert("Travelbooster API can't handle this many request");
        }
        // handle error
        console.log(error);
      })
      .finally(function () {
        setIsSearching(false);
      });
  };

  const onImport = async (e) => {
       let concatenatedCommand = ([]);
    let paxNumber = 0;
    let checkbf = false;
    for (let i = 0; i < selectedResult.length; i++) {
      const matched = searchResult.find((y) => y.Index === selectedResult[i]);
      const bf = JSON.parse(bookingFile);
      let command = "DI.FT-TB-" + matched.Index;
      paxNumber = paxNumber + 1;
      let title = matched.Title === null ? "" : matched.Title;
      const matchedPax = bf.Passengers.find(
        (x) =>
          x.LastName + "/" + x.FirstNameAndPrefix ===
          matched.LastName + "/" + matched.FirstName + title
      );
      if (typeof matchedPax === "undefined") {
        command +=
          "+N." +
          matched.LastName +
          "/" +
          matched.FirstName +
          (matched.Title === null ? "" : matched.Title);
        if (bf.Passengers.length > 0 && checkbf == false) {
          paxNumber = bf.Passengers.length + 1;
          checkbf = true;
        }
      } else {
        paxNumber = matchedPax.PassengerNumber;
      }

      if (matched.IsPhoneSelected && matched.PhonesList.length > 0) {
        command += "+P.H*" + matched.PhonesList[0];
        command +=
          "+SI.P" +
          paxNumber +
          "/SSRCTCMYYHK1/" +
          matched.PhonesList[0] +
          "/EN";
      }

      if (
        matched.IsEmailSelected &&
        matched.EMail != null &&
        matched.EMail !== ""
      ) {
        let newEmail = matched.EMail;
        command +=
          "+P.SYDE*" +
          newEmail.replace("_", "--").replace("@", "//").toUpperCase();
        command +=
          "+SI.P" +
          paxNumber +
          "/SSRCTCEYYHK1/" +
          newEmail.replace("_", "--").replace("@", "//").toUpperCase();
      }

      if (
        matched.IsPassportSelected &&
        matched.Passports.length > 0 &&
        bf.AirSegments.length > 0
      ) {
        matched.Passports.forEach((element) => {
          bf.AirSegments.forEach((segment) => {
            let Issuingcountry = "";
            const matchedCountry = countries.find(
              (x) => x.ID === element.IssuingCountryId
            );
            if (typeof matchedCountry === "undefined") {
              Issuingcountry = element.Nationality;
            } else {
              Issuingcountry = matchedCountry.Code;
            }
            let bd = new Date(matched.BirthDate);
            let ed = new Date(element.ExpirationDate);
            bd = bd.toDateString();
            ed = ed.toDateString();
            bd = bd.toString().split(/(\s+)/);
            ed = ed.toString().split(/(\s+)/);
            command +=
              "+SI.P" +
              paxNumber +
              "/SSRDOCS" +
              segment.Carrier.Code +
              "HK1/P/" +
              Issuingcountry +
              "/" +
              element.PassportNumber +
              "/" +
              element.Nationality +
              "/" +
              (bd[4] + bd[2] + bd[6].slice(2, 4)) +
              "/" +
              matched.Gender +
              "/" +
              (ed[4] + ed[2] + ed[6].slice(2, 4)) +
              "/" +
              matched.LastName +
              "/" +
              matched.FirstName +
              title;
          });
        });
      }

      if (
        matched.IsFrequentFlyerNumberSelected &&
        matched.FrequentFlyerData.length > 0 &&
        bf.AirSegments.length > 0
      ) {
        matched.FrequentFlyerData.forEach((element) => {
          const matchedAirline = bf.AirSegments.find(
            (x) => x.Carrier.Code === element.FrequentFlyerCompanyIata
          );
          const matchedPax = bf.Passengers.find(
            (x) =>
              x.LastName + "/" + x.FirstNameAndPrefix ===
              matched.LastName + "/" + matched.FirstName + title
          );
          if (
            typeof matchedAirline !== "undefined" &&
            typeof matchedPax !== "undefined"
          ) {
            command +=
              "+M.P" +
              matchedPax.PassengerNumber.toString() +
              "/" +
              element.FrequentFlyerCompanyIata +
              element.FrequentFlyerNumber;
          } else if (
            typeof matchedAirline !== "undefined" &&
            typeof matchedPax === "undefined" &&
            paxNumber !== 0
          ) {
            command +=
              "+M.P" +
              paxNumber.toString() +
              "/" +
              element.FrequentFlyerCompanyIata +
              element.FrequentFlyerNumber;
          }
        });
      }
      concatenatedCommand.push(command);
    }
    await spHelper.importProfile(concatenatedCommand);
    setRefreshPnr(refreshPnr+1);
    alert("Traveler data successfully imported, please check in Smartpoint");
  };
  const columns = [
    { field: "Index", headerName: "ID", width: 100 },
    { field: "FirstName", headerName: "First Name", width: 150 },
    { field: "LastName", headerName: "Last Name", width: 150 },
    {
      field: "IsEmailSelected",
      headerName: "Email",
      type: "bool",
      width: 200,
      renderCell: (params) => {
        if (params.row.EMail != null) {
          const selectedRow = searchResult.find((t) => t.Index === params.id);
          return (
            <>
              <span>{params.row.EMail}</span>
              <Checkbox
                style={{ marginLeft: 15, marginBottom: 0 }}
                checked={selectedRow.IsEmailSelected}
                onChange={(e) => {
                  selectedRow.IsEmailSelected = !selectedRow.IsEmailSelected;
                  setRenderAgain(selectedRow.IsEmailSelected);
                }}
                disabled={
                  !selectedResult.length ||
                  !selectedResult.find((r) => r === params.id)
                }
              ></Checkbox>
            </>
          );
        }
      },
    },
    {
      field: "IsPhoneSelected",
      headerName: "Phone",
      type: "bool",
      width: 200,
      renderCell: (params) => {
        if (params.row.PhonesList.length > 0) {
          const selectedRow = searchResult.find(
            (t) => t.Index === params.id
          );
          return (
            <>
              <span>{params.row.PhonesList[0]}</span>
              <Checkbox
                style={{ marginLeft: 15, marginBottom: 0 }}
                checked={selectedRow.IsPhoneSelected}
                onChange={(e) => {
                  selectedRow.IsPhoneSelected = !selectedRow.IsPhoneSelected;
                  setRenderAgain(selectedRow.IsPhoneSelected);
                }}
                disabled={
                  !selectedResult.length ||
                  !selectedResult.find((r) => r === params.id)
                }
              ></Checkbox>
            </>
          );
        }
      },
    },
    {
      field: "IsPassportSelected",
      headerName: "Passport",
      type: "bool",
      width: 200,
      renderCell: (params) => {
        let pass = "";
        if (params.row.Passports.length > 0) {
          pass = params.row.Passports[0].PassportNumber;
        }
        if (pass !== "") {
          const selectedRow = searchResult.find(
            (t) => t.Index === params.id
          );
          return (
            <>
              <span>{pass}</span>
              <Checkbox
                style={{ marginLeft: 15, marginBottom: 0 }}
                checked={selectedRow.IsPassportSelected}
                onChange={(e) => {
                  selectedRow.IsPassportSelected =
                    !selectedRow.IsPassportSelected;
                  setRenderAgain(selectedRow.IsPassportSelected);
                }}
                disabled={
                  !selectedResult.length ||
                  !selectedResult.find((r) => r === params.id)
                }
              ></Checkbox>
            </>
          );
        }
      },
    },
    {
      field: "IsFrequentFlyerNumberSelected",
      headerName: "FrequentFlyerNumber",
      type: "bool",
      width: 275,
      renderCell: (params) => {
        let pass = "";
        if (
          params.row.FrequentFlyerData != null &&
          params.row.FrequentFlyerData.length > 0
        ) {
          params.row.FrequentFlyerData.forEach((element) => {
            pass = `${pass + element.FrequentFlyerNumber}( ${element.FrequentFlyerCompanyIata
              }),`;
          });
        }
        if (pass !== "") {
          const selectedRow = searchResult.find(
            (t) => t.Index === params.id
          );
          return (
            <>
              <Typography variant="body2" sx={{ whiteSpace: "normal" }}>
                {" "}
                {pass.slice(0, -1)}
              </Typography>
              <Checkbox
                style={{ marginLeft: 15, marginBottom: 0 }}
                checked={selectedRow.IsFrequentFlyerNumberSelected}
                onChange={(e) => {
                  selectedRow.IsFrequentFlyerNumberSelected =
                    !selectedRow.IsFrequentFlyerNumberSelected;
                  setRenderAgain(selectedRow.IsFrequentFlyerNumberSelected);
                }}
                disabled={
                  !selectedResult.length ||
                  !selectedResult.find((r) => r === params.id)
                }
              ></Checkbox>
            </>
          );
        }
      },
    },
  ];
  const searchResultSection =
    searchResult.length !== 0 && !isSearching ? (
      <>
        <div
          style={{ height: "400px", width: "100%" }}
          className="ag-theme-alpine"
        >
          <h2>Search Result</h2>
          <DataGrid
            className="ag-theme-alpine"
            rows={searchResult}
            renderAgain={renderAgain}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableSelectionOnClick
            checkboxSelection
            getRowId={(row) => row?.Index}
            onSelectionModelChange={(ids) => {
              setSelectedResult(ids);
            }
            }
          />
        </div>{" "}
        <div>
          {selectedResult.length !== 0 ? (
            <Button
              variant="contained"
              onClick={async () => {
                await onImport();
              }}
            >
              Import To Smartpoint
            </Button>
          ) : null}
        </div>
      </>
    ) : <Typography>No result found</Typography>;
  const searching = isSearching ? <CircularProgress /> : null;
  return (
    <div>
      {tokenApi ? (
        <div>
          <Grid>
            <Grid xs={12}>
              <Item>
                <h2>Search Traveller</h2>
              </Item>
            </Grid>
            <Grid height={60} xs={12} justifyContent="center">
              <Item>
                <TextField
                  id="outlined-required"
                  label="Index (  customer’s Travel Booster id )"
                  value={contact.index}
                  onChange={(e) => {
                    setContact({ ...contact, index: e.target.value });
                  }}
                />
                &nbsp;
                <TextField
                  id="outlined-required"
                  label="First Name"
                  value={contact.firstname}
                  onChange={(e) => {
                    setContact({ ...contact, firstname: e.target.value });
                  }}
                />
                &nbsp;
                <TextField
                  id="outlined-required"
                  label="Last Name"
                  value={contact.lastname}
                  onChange={(e) => {
                    setContact({ ...contact, lastname: e.target.value });
                  }}
                />
              </Item>
            </Grid>
            <Grid xs={12} justifyContent="center">
              <Item>
                <TextField
                  re
                  id="outlined-required"
                  label="Phone"
                  value={contact.phone}
                  onChange={(e) => {
                    setContact({ ...contact, phone: e.target.value });
                  }}
                />
                &nbsp;
                <TextField
                  id="outlined-required"
                  label="Email"
                  value={contact.email}
                  onChange={(e) => {
                    setContact({ ...contact, email: e.target.value });
                  }}
                />
                &nbsp;
                <TextField
                  id="outlined-required"
                  label="PersonalId"
                  value={contact.personalid}
                  onChange={(e) => {
                    setContact({ ...contact, personalid: e.target.value });
                  }}
                />
              </Item>
            </Grid>
            <Grid xs={12} justifyContent="center">
              <Item>
                <Button variant="contained" onClick={handleSearch}>
                  Search
                </Button>
              </Item>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Box sx={{ width: "75%", margin: "auto", marginTop: "10px" }}>
          Please wait while connecting to TravelBooster...
        </Box>
      )}
      {searching}
      {searchResultSection}
    </div>
  );
};

export default SearchApi;
